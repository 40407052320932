import * as React from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Button, MenuItem, CssBaseline } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-scroll";
import Logo from '../images/Logo.svg'

const pages = ['About', 'Features', 'Contact'];
const desktopPages = ['About', 'Features', 'Contact'];

const Navbar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <div className="Navbar">
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: 'white',
                    color: 'black',
                }}
            >
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Box sx={{
                            display: { xs: 'none', md: 'flex' }
                        }}>
                            <img src={Logo} alt="Logo" style={{ height: "1.5rem", paddingRight: "0.5rem" }} />
                        </Box>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontWeight: 700,
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            GrantApp
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page}>
                                        <Link
                                            activeClass="active"
                                            onClick={handleCloseNavMenu}
                                            to={page}
                                            spy={true}
                                            smooth={true}
                                            offset={-70}
                                            duration={500}
                                        >
                                            {page}
                                        </Link>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Box
                            sx={{
                                display: { xs: 'flex', md: 'none' },
                                mr: 2,
                                flexGrow: 1,
                            }}
                        >
                            <img src={Logo} alt="Logo" style={{ height: "1.5rem", paddingRight: "0.5rem" }} />
                            <Typography
                                variant="h5"
                                noWrap
                                component="a"
                                href=""
                                sx={{
                                    fontWeight: 700,
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                GrantApp
                            </Typography>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {desktopPages.map((page) => (
                                <Button
                                    key={page}
                                    sx={{ my: 2, color: 'inherit', display: 'block' }}
                                >
                                    <Link
                                        activeClass="active"
                                        onClick={handleCloseNavMenu}
                                        to={page}
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}
                                    >
                                        {page}
                                    </Link>
                                </Button>
                            ))}
                        </Box>

                        <Box
                            sx={{
                                flexGrow: 0,
                                display: { xs: 'none', md: 'flex' },
                            }}
                        >
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
};
export default Navbar;
import * as React from 'react';
import { Typography, Button, Stack, Grid } from '@mui/material';

const Contact = () => {
    return (
        <Grid
            container
            className="Contact"
            id="Contact"
            sx={{
                backgroundColor: "#F2F2F2",
            }}
            p={{ xs: 4, md: 10 }}
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
        >
            <Grid
                item
                xs={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Typography variant='h4'>
                   Excited to learn more?
                </Typography>
            </Grid>
            <Grid
                item
                xs={6}
            >
                <Stack
                    spacing={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    direction={{ xs: 'column', md: 'row' }}
                >
                    <Button
                        variant='contained'
                        sx={{
                            maxWidth: 300
                        }}
                        href={`mailto:joingrantapp@gmail.com`}
                    >
                        Contact Us
                    </Button>
                </Stack>
            </Grid>
        </Grid >
    );
};
export default Contact;
import * as React from 'react';
import { Box, Typography } from '@mui/material';


const About = () => {

    return (
        <Box
            className="About"
            id="About"
            sx={{
                backgroundColor: 'black',
                p: { xs: 2, md: 20 },
            }}
            mb={2}
        >
            <Box
                sx={{
                    maxWidth: { xs: 300, md: 800 },
                    textAlign: 'center',
                    margin: 'auto',
                }}
            >
                <Typography
                    variant='h1'
                    align='center'
                    display='inline-flex'
                >
                    Revolutionizing how
                </Typography>
                <Typography variant='h1' align='center' display='inline-flex' sx={{ color: 'primary.main' }}>
                    &nbsp;nonprofit organizations&nbsp;
                </Typography>
                <Typography
                    variant='h1'
                    align='center'
                    display='inline-flex'
                    sx={{
                        mb: 2,
                    }}
                >
                    apply for grants.
                </Typography>
            </Box>
            <Box
                sx={{
                    maxWidth: { xs: 300, md: 600 },
                    margin: 'auto',
                    textAlign: 'center',
                }}
            >
                <Typography
                    variant='subtitle1'
                    align='center'
                    sx={{
                        mb: 2,
                    }}
                >
                    GrantApp is a common grant application for nonprofits and philanthropists.
                </Typography>
            </Box>
        </Box >
    );
};
export default About;